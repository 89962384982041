import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/post-18.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";

export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "pulpit-klienta-b2b.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "laptop-aura-b2b.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "podglad-zapytan.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
  }
`;

const Urodziny = ({ data }) => {
  return (
    <Artykul
      title="18. urodziny Questy"
      keywords={["18. urodziny questy"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="18. urodziny Questy"
      metaTitle="18. urodziny Questy"
      metaDescription="18. urodziny Questy ✅ W listopadzie 2020 roku Questy obchodzi 18 urodziny "
    >
      <p>
        Jesień to dla naszej firmy czas jubileuszu. Ten rok jest dla nas
        wyjątkowy nie tylko ze względu na panującą pandemię. W listopadzie 2020
        roku obchodzimy osiemnaste urodziny. Firma w świetle prawa stała się
        pełnoletnia. Dziękujemy wszystkim pracownikom, którzy przyłożyli rękę do
        rozwoju Questy. Podziękowania kierujemy także do naszych klientów,
        którzy obdarzyli nas zaufaniem i wykazywali się szczególną cierpliwością
        przez te wszystkie lata współpracy.
      </p>
      <br />

      <p>
        Z okazji urodzin planowaliśmy zorganizować wielką imprezę i z hukiem
        rozpocząć kolejnych osiemnaście lat stabilnego rozwoju. Niestety
        rzeczywistość pokrzyżowała nam plany. Sytuacja związana z pandemią
        sprawiła, że obecnie cały zespół pracuje zdalnie, więc nasze wydarzenie
        będzie musiało poczekać na lepszy czas, prawdopodobnie do wiosny. Nic
        jednak nie stało na przeszkodzie, byśmy złożyli sobie urodzinowe
        życzenia. Życzymy sobie i Wam przede szybkim szybkiego powrotu do
        normalności. Czego jeszcze? Zobaczcie w krótkim filmie, który
        przygotowaliśmy specjalnie z okazji urodzin.
      </p>
      <br />
      <center>
        <iframe
          width="560"
          height="560"
          src="https://www.youtube.com/embed/uFNMvjifXjE?controls=0"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <br />
      </center>

      <br />
    </Artykul>
  );
};

export default Urodziny;
